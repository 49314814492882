/**
 * Ukoo Email Subscription
 *
 * @author      Ukoo <support@ukoo.pro> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 */
$(document).ready(function () {
    $('.block_newsletter form').on('submit', function () {
        var psemailsubscriptionForm = $(this);
        if (typeof psemailsubscription_subscription === 'undefined') {
            return true;
        }
        $('.block_newsletter_alert').remove();
        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            url: psemailsubscription_subscription,
            cache: false,
            data: $(this).serialize(),
            success: function (data) {
                if (data.nw_error) {
                    psemailsubscriptionForm.prepend('<p class="alert alert-danger block_newsletter_alert">' + data.msg + '</p>');
                } else {
                    psemailsubscriptionForm.prepend('<p class="alert alert-success block_newsletter_alert">' + data.msg + '</p>');
                }
            },
            error: function (err) {
                console.log(err);
            }
        });
        return false;
    });

    // Pour chaque checkbox globale
    $(".checkAllThematic").each(function(){
        var checkbox = $(this);
        var thematic_id = checkbox.val();
        var allChecked = $(".list-group_" + thematic_id).find(".custom-control-input").length === $(".list-group_" + thematic_id).find(".custom-control-input:checked").length;
        checkbox.prop("checked", allChecked);
        updateCheckAllText(checkbox, allChecked);
    });

    // Au clic sur une checkbox globale
    $(".checkAllThematic").change(function(){
        handleCheckAllChange($(this));
    });

    // Au changement d'une checkbox individuelle
    $(".list-group .custom-control-input").change(function(){
        var thematic_id = $(this).parents(".thematic").find(".checkAllThematic").val();
        var checkbox = $(".thematic_" + thematic_id).find(".checkAllThematic");
        var allChecked = $(".list-group_" + thematic_id).find(".custom-control-input").length === $(".list-group_" + thematic_id).find(".custom-control-input:checked").length;
        checkbox.prop("checked", allChecked);
        updateCheckAllText(checkbox, allChecked);
    });
});

function updateCheckAllText(checkbox, isChecked) {
    if (isChecked) {
        checkbox.next('label').text(checkbox.data('uncheckall'));
    } else {
        checkbox.next('label').text(checkbox.data('checkall'));
    }
}

// Fonction pour gérer le changement d'état d'une checkbox globale
function handleCheckAllChange(checkbox) {
    var thematic_id = checkbox.val();
    var isChecked = checkbox.prop("checked");
    $(".list-group_" + thematic_id).find(".custom-control-input").prop("checked", isChecked);
    updateCheckAllText(checkbox, isChecked);
}